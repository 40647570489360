<style>
body {
  padding-top: 85px !important;
}
#noitaler-navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  /* height: 85px; */
  background-color: rgba(255,255,255,0.7);
  backdrop-filter: blur(5px);
}
.noitaler-container {
  width: 935px;
  height: 85px;
  /* border-bottom: solid 1px #e2e2e2; */
  margin: 0 auto;
  line-height: 85px;
  display: flex !important;
}
.noitaler-logo {
  background: url(https://oopy.lazyrockets.com/api/v2/notion/image?src=https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Faf441098-b9ef-45c6-a8fb-d57e29b06822%2FKakaoTalk_Photo_2021-03-15-17-20-28.png&blockId=900248c5-cb32-435b-86cd-cccce2077d91);
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: center center;
  width: 150px;
  display: inline-block;
  text-align: center;
  color: rgba(255,255,255,0)
}
.noitaler-logo:hover {
  color: rgba(255,255,255,0)
}

/* 메뉴 오른쪽 영역 */
.noitaler-right {
  margin: 0 0 0 auto;
  font-size: 14px;
}
/* 메뉴 오른쪽 링크 */
.noitaler-link {
  color: #333;
  font-weight: 500;
  text-decoration: none;
  padding: 0 1rem;
  display: inline-block;
}
.noitaler-link:hover {
  color: #333;
  font-weight: 700;
  text-decoration: none;
}
/* 메뉴 오른쪽 CTA */
.noitaler-link-action {
  margin-left: 1rem;
  font-weight: 600;
  background-color: #287bff;
  color: #fff;
  min-width: 170px;
  /* padding: 0 2rem; */
  line-height: 3rem;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}
.noitaler-link-action:hover {
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}
.noitaler-transition {
  /* transition: all 300ms; */
}
/* @media (max-width: 576px) { */
/* @media (max-width: 801px) { */
@media (max-width: 960px) {
  #noitaler-navbar {
    /* height: 55px; */
    overflow: scroll;
  }
  .noitaler-container {
    width: 100%;
    /* 크기부터는 스크롤 */
    min-width: 418px;

    height: 55px;
    line-height: 55px;

    border-bottom: solid 1px #e2e2e2;
    padding: 0 0.75rem;
  }
  .noitaler-logo {
    width: 100px;
    background-size: 100px;
  }
  .noitaler-link {
    padding: 0 0.5rem;
  }
  .noitaler-link-action {
    margin-left: 0.5rem;

    min-width: 136px !important;
    /* padding: 0 1rem; */
    line-height: 2.25rem;
  }
  .noitaler-transition {
    transition: all 200ms;
  }
}
</style>
<template>
<div>
  <nav id='noitaler-navbar'>
    <div class="noitaler-container">
      <a href='#메인' class="noitaler-logo noitaler-transition" title="노이텔러">noitaler</a>
      <div class="noitaler-right">
        <a href='#서비스소개' class="noitaler-link noitaler-transition">서비스소개</a>
        <a href='#기능소개' class="noitaler-link noitaler-transition">기능소개</a>
        <a href='#사전등록' class="noitaler-link-action noitaler-transition">지금 사전 등록하기</a>
      </div>
    </div>
  </nav>
  <div class="container pt-4">
    <h3>Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</h3>
    <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
    <p>1914 translation by H. Rackham</p>
    <p>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"</p>
    <hr class="my-4 py-4" />
    <h3>Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</h3>
    <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
    <p>1914 translation by H. Rackham</p>
    <p>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"</p>
    <hr class="my-4 py-4" />
  </div>
</div>
</template>

<script>

export default {
  name: 'index',
}
</script>
